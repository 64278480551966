import React, { useState, useEffect, useCallback } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import AppContainer from '../Components/AppContainer';
import MessageViewer from '../Components/MessageViewer';
import MessageEditor from '../Components/MessageEditor';
import ApiMessageEditor from '../Components/ApiMessageEditor';
import withStyle from '../style';
import Loader from '../Components/Loader';
import MadeWithLove from '../Components/MadeWithLove';

import Networker from '../Utils/Networker';

const getAllNotifications = (prodId) => {
  return Networker.get({
    root: 'notifications',
    query: {
      productIds: [prodId]
    },
  }).then(res => {
    return Promise.resolve(res);
  })
    .catch(er => {
      return Promise.reject(er);
    });
}


const getAllAdminProducts = (org) => {
  return Networker.get({
    root: 'products',
    inner: 'admin',
    cache: true
  });
};

const getAllSUProducts = () => {
  return Networker.get({
    root: 'products',
    inner: 'all',
    cache: true
  });
};
const getProduct = (currentTab) => {
  return Networker.get({
    root: 'products',
    query: {
      _id: [currentTab]
    },
    cache: true
  });
};

const getUserSession = () => {
  return Networker.getUserSession();
};


export default function ConsumerConnect({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [products, setProducts] = useState([]);
  const [notUpgraded, setNotUpgraded] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [notificationToEdit, setNotificationToEdit] = useState(null);
  const [notificationToEditType, setNotificationToEditType] = useState(null);
  const currentTab = match.params.productId;
  // const [externalLinks, setExternalLinks] = useState([]);
  const [product, setProduct] = useState(null);

  const refreshProducts = useCallback(() => {
    setLoading(true);
    getUserSession()
      .then(async res => {
        const getFunc = res.body.isSuperUser ? getAllSUProducts : getAllAdminProducts;
        try {
          const prods = await getFunc();
          const newProds = Array.from(new Set(prods.body));
          const filteredProds = newProds.filter(prod => {
            if (res.body.isSuperUser) {
              setNotUpgraded(false);
              return true;
            }
            return (prod.tier || [])
              .some((tier) => ['CONNECT','VOLUMETRIC','ANALYTICS'].includes(tier));
          }).sort((a, b) => {
            return a.name > b.name ? 0 : -1;
          });
          if (newProds.length > 0 && filteredProds.length === 0) {
            setNotUpgraded(true);
            Networker.interactionBeacon({
              message: 'non-upgraded consumer connect view',
            });
          } else {
            setProducts(filteredProds);
          }
        } catch (e) {
          console.error(e);
          Networker.sendError({
            message: 'consumer connect fetch error',
            namespace: 'dashboard.connect',
            data: e
          });
        }
        setLoading(false);
      })
      .catch(e => {
        window.alert('Network error');
        setLoading(false);
        Networker.sendError({
          message: 'consumer connect fetch error',
          namespace: 'dashboard.connect',
          data: e
        });
      });
  }, []);

  const refreshNotifications = useCallback(() => {
    setLoading(true);
    getAllNotifications(currentTab)
      .then((res) => {
        setNotifications(res.body);
        setLoading(false);
      })
      .catch((err) => {
        Networker.sendError({
          message: 'consumer connect fetch error',
          namespace: 'dashboard.connect',
          data: err
        });
        setLoading(false);
      })
  }, [currentTab])

  useEffect(() => {
    if (!currentTab && products.length > 0) {
      history.push('/messages/' + products[0]._id)
    } else if (currentTab && products.length > 0) {
      const prod = products.find(prod => {
        return prod._id === currentTab;
      });
      setCurrentProduct(prod);
    } else {
      refreshProducts();
    }
  }, [currentTab, history, products, refreshProducts]);

  useEffect(() => {
    refreshNotifications();
  }, [currentTab, refreshNotifications]);


  useEffect(() => {
    getProduct(currentTab)
      .then((data) => {
        const { body } = data;
        setProduct(body);
        
        // Find the object in the body array that matches the currentTab
        const selectedProduct = body.find((item) => item._id === currentTab);

        // If a matching object is found, set it as the product
        if (selectedProduct) {
          setProduct(selectedProduct);
        } else {
          // If no matching object is found, set product to null or handle it accordingly
          setProduct(null);
        }
      })
      .catch((err) => {
        console.log(err)
        setProduct(null);
      })
  }, [currentTab, refreshNotifications]);

  const handleTabChange = (val) => (e) => {
    setLoading(true);
    setNotifications([]);
    setNotificationToEdit(null);
    history.push('/messages/' + val);
  };

  const handleSave = (notificationToEdit) => {
    setLoading(true);
    if (!currentProduct || !notificationToEdit) {
      setNotificationToEdit(null);
      setLoading(false);
      return;
    }
    if (notificationToEdit) {
      if (notificationToEdit.trigger.type === 'custom') {
        notificationToEdit.trigger.value = JSON.stringify(notificationToEdit.trigger.value);
      }
      Networker.put({
        root: 'notifications',
        body: {
          ...notificationToEdit,
        }
      })
        .then(async res => {
          setNotificationToEdit(null);
          refreshNotifications();
          setLoading(false);
        })
        .catch(err => {
          console.error(err);
          Networker.sendError({
            message: 'consumer connect save error',
            namespace: 'dashboard.connect',
            data: err
          });
          setLoading(false);
        })
    }
  };


  const handleEdit = (e) => {
    let node = e.target;
    let i = 0;
    while (node.nodeName !== 'BUTTON' && i < 4) {
      node = node.parentNode;
      i += 1;
    }
    const notificationId = node.id;
    const toEdit = notifications.find(({ _id }) => _id === notificationId);
    if (toEdit) {
      setNotificationToEdit(toEdit);
      setNotificationToEditType('user');

    }
  };


  const handleDelete = (e, notificationid) => {
    let node = e.target;
    let i = 0;
    while (node.nodeName !== 'BUTTON' && i < 4) {
      node = node.parentNode;
      i += 1;
    }
    const notificationId = node.id;

    setLoading(true);
    // if (window.confirm('Are you sure you want to delete this notification?')) {
    Networker.delete({
      root: 'notifications',
      query: {
        _id: notificationid ? notificationid : notificationId
      }
    })
      .then(async res => {
        refreshNotifications();
      })
    // } else {
    setLoading(false);
    // }
  };

  const handleCreateUSerNotification = (e, notification = null) => {

    if (notification?._id) {
      delete notification._id
      delete notification.__v
    }

    const newNotification = notification ? notification : {
      message: 'Hey there, thanks for using our product!',
      subject: '',
      trigger: {
        type: 'all',
        field: '',
        value: ''
      },
      active: false,
      notificationType: 'text'
    };
    Networker.post({
      root: 'notifications',
      body: {
        ...newNotification,
        productIds: [currentTab]
      }
    })
      .then(async res => {
        await refreshNotifications();
        setNotificationToEdit(notifications.find(({ _id }) => {
          return _id === res.body._id;
        }));
      })
      .catch(err => {
        console.error(err);
        Networker.sendError({
          message: 'consumer connect save error',
          namespace: 'dashboard.connect',
          data: err
        });
        setLoading(false);
      })
  }

  const handleCreateApiNotification = () => {
    const newNotification = {
      message: '<api only>',
      subject: '<api only>',
      trigger: {
        type: 'all',
        field: '',
        value: ''
      },
      active: false,
      notificationType: 'api',
      apiConfiguration: {
        endpointUrl: 'https://api.test.com',
        httpMethod: 'GET',
        headers: [
          {
            headerName: 'Content-Type',
            headerValue: 'application/json',
          },
        ],
        requestBody: JSON.stringify({
          email: '[email]',
          timestamp: '[timestamp]',
          productId: '[productId]'
        }),
      },

    };
    Networker.post({
      root: 'notifications',
      body: {
        ...newNotification,
        productIds: [currentTab]
      }
    })
      .then(async res => {
        await refreshNotifications();
        setNotificationToEdit(notifications.find(({ _id }) => {
          return _id === res.body._id;
        }));
      })
      .catch(err => {
        console.error(err);
        Networker.sendError({
          message: 'consumer connect save error',
          namespace: 'dashboard.connect',
          data: err
        });
        setLoading(false);
      })
  }

  const classes = withStyle();

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    };

    // const handleUpdate = (e) => {
    //   // console.log(e.target)
    //   const [idx, field] = e.target.id.split(' ');
    //   // const {meta} = externalLinks[idx];
    //   // meta[field] = e.target.value;
    //   // const newLinks = Object.assign([], externalLinks);
    //   // newLinks.splice(idx, 1, Object.assign(externalLinks[idx], {meta}));
    //   // setExternalLinks(newLinks);
    //   const newLinks = [...externalLinks]; // Create a copy of externalLinks
    //   const linkToUpdate = { ...newLinks[idx] }; // Create a copy of the link to update

    //   if (field === 'name' || field === 'authToken') {
    //     // If the field is 'name' or 'authToken', update inside the meta object
    //     linkToUpdate.meta = { ...linkToUpdate.meta, [field]: e.target.value };
    //   } else if (field.startsWith('meta')) {
    //     // If the field belongs to the meta object
    //     const metaField = field.split('.')[1]; // Extract the meta field name
    //     linkToUpdate.meta = { ...linkToUpdate.meta, [metaField]: e.target.value };
    //   } else {
    //     // If the field is a direct property of externalLinks
    //     linkToUpdate[field] = e.target.value;
    //   }
    //   console.log(linkToUpdate)

    //   newLinks[idx] = linkToUpdate; // Update the link in the copied array
    //   setExternalLinks(newLinks); // Update the state with the modified array
    // };

    // const handleAdd = () => {
    //   setExternalLinks(externalLinks.concat({
    //     service: 'surveymonkey', identifier: '', meta: { authToken: '', name: '' }
    //   }));
    // };



    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleTabPanelChange = (event, newValue) => {
    setValue(newValue);
  };

  return <div className={classes.root}>
    <CssBaseline />
    <AppContainer classes={classes} title="Consumer Connect" match={match}>
      <div className={classes.appBarSpacer} />
      <Grid container alignItems="flex-start" justifyContent="space-between" spacing={2}>
        {notUpgraded ? <Grid item xs={6}>
          <Typography variant="h4">
            Upgrade to the <strong>Consumer Connect</strong> package to get
            real-time, 2-way dialogue with your consumer through Email,
            SMS, and Push Notifications!
          </Typography>
        </Grid>
          : <Grid item xs={12} md={3}>
            <Paper>
              <List className={classes.connectList} dense>
                {products.map(prod => {
                  return <ListItem key={prod._id} button divider
                    dense
                    selected={currentTab === prod._id}
                    onClick={handleTabChange(prod._id)}>
                    <ListItemAvatar>
                      <Avatar alt={prod.name.substr(0, 3)} src={prod.iconURL} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={prod.name}>
                    </ListItemText>
                  </ListItem>
                })}
              </List>
            </Paper>
          </Grid>}
        <Grid xs={12} md={9} item>
          {currentProduct ?
            <Card>
              <CardHeader
                title={
                  <Typography variant="h5">
                    {currentProduct.name}
                  </Typography>}
              >
              </CardHeader>
              <CardContent>
                {
                  // tab implementation here... 
                }
                <AppBar position="static">
                  <Tabs value={value} onChange={handleTabPanelChange} aria-label="simple tabs example">
                    <Tab label="User Notifications" {...a11yProps(0)} />
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                  <Grid container>
                    <Grid item xs={10}>
                      &nbsp;
                    </Grid>
                    <Grid item xs={2}>
                      <Button fullWidth
                        onClick={handleCreateUSerNotification}
                        color="secondary"
                        variant="contained">
                        New
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container justifyContent="center"
                    alignItems="flex-start" spacing={3}>
                    {notifications
                      .filter(notification => notification.notificationType.toLowerCase() !== 'api')
                      .map(notification => {
                        return <Grid item xs={12} md={6} key={notification._id}>
                          <MessageViewer
                            onDelete={handleDelete}
                            onEdit={handleEdit}
                            onCopy={handleCreateUSerNotification}
                            notification={notification} />
                        </Grid>;
                      })}
                  </Grid>
                  <Grid item xs={12}>
                    {!loading && notifications.length === 0 ? <Typography variant="h6" display="block">
                      This product has no connections set up
                    </Typography> : null}
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Grid container>
                    <Grid item xs={10}>
                      &nbsp;
                    </Grid>
                    <Grid item xs={2}>
                      <Button fullWidth
                        onClick={handleCreateApiNotification}
                        color="secondary"
                        variant="contained">
                        New
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {!loading && notifications.length === 0 ? <Typography variant="h6" display="block">
                      This product has no connections set up
                    </Typography> : null}
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  Item Three
                </TabPanel>


              </CardContent>
            </Card> : null}
        </Grid>
      </Grid>
      <Dialog open={!!notificationToEdit}
        fullWidth
        maxWidth="md"
        onClose={() => { handleSave(null) }}>
        <DialogTitle id="form-dialog-title">
          Edit
        </DialogTitle>
        <DialogContent>
          {notificationToEditType === 'user' ?
            <MessageEditor
              notification={notificationToEdit}
              handleSaveNotification={handleSave}
              currentTab={currentTab}
              productData={product}>
            </MessageEditor>
            : <ApiMessageEditor
              notification={notificationToEdit}
              handleSaveNotification={handleSave}>
            </ApiMessageEditor>
          }

        </DialogContent>
      </Dialog>
      <MadeWithLove />
      <Loader show={loading} />
    </AppContainer>
  </div>;
}
