import superagent from 'superagent';
import io from 'socket.io-client';

const LOCAL = process.env.REACT_APP_LOCALHOST_BACKEND === 'true';

export const HOST = LOCAL ? 'http://localhost' : ({
    master: 'https://api.adrich.io',
    development: 'https://test2.adrich.io',
    qa: 'https://qa.adrich.io'
}[process.env.REACT_APP_ENV || 'development']);

export const BACKEND_PORT = LOCAL ? 4000 : 3000;

const queryString = (query) => query ? Object.keys(query || {}).map(key => `${key}=${query[key]}`).join('&') : '';

const SESSION_TTL = 20 * 60 * 1000;
const REQUEST_TTL = 2 * 60 * 1000;

const EMPTY_CB = function (_) {};

export default class Networker
{
    static socket = io(`${HOST}:${BACKEND_PORT}/`, { transports: ['websocket'] });
    static req = superagent.agent();
    static cache = {};
    static miss = 0;
    static hit = 0;

    static cacheRequest = function (tag, res) {
        this.cache[tag] = {
            t: Date.now(),
            b: res
        };
    }

    static invalidateRequest = function (tag) {
        delete this.cache[tag];
    }

    static _invalidateModified(root) {
        Object.keys(this.cache).forEach((key) => {
            if (key.startsWith(root)) {
                this.invalidateRequest(key);
            }
        })
    }

    static getCached = function (tag, ttl) {
        if (this.cache[tag] && Date.now() - this.cache[tag].t < ttl) {
            this.hit += 1;

            return this.cache[tag].b;
        } else {
            this.invalidateRequest(tag);
            this.miss += 1;

            return null;
        }
    }

    static generatePasswordResetToken({ email }) {
        this.cache = {};

        return this.req.post(`${HOST}:${BACKEND_PORT}/user/generatetoken`).send({ email }).then(() => Promise.resolve()).catch((err) => {
            window.alert(err);
            return Promise.reject();
        });
    }

    static resetPassword({ email, password, token }) {
        this.cache = {};

        return this.req.post(`${HOST}:${BACKEND_PORT}/user/resetpassword/${token}`).send({ email, password }).withCredentials().then(() => Promise.resolve()).catch((err) => {
            window.alert(err);
            return Promise.reject();
        });
    }

    static register({ email, password, notificationsEnabled, meta }) {
        return this.req.post(`${HOST}:${BACKEND_PORT}/user/register`).send({ email, password, notificationsEnabled, meta }).withCredentials().then(() => Promise.resolve());
    }

    static signIn({ email, password }) {
        return this.req.post(`${HOST}:${BACKEND_PORT}/user/login`).send({ email, password }).withCredentials().then(() => Promise.resolve());
    }

    static logOut() {
        this.cache = {};

        return this.req.post(`${HOST}:${BACKEND_PORT}/user/logout`).withCredentials();
    }

    static getTag({ root, inner, query }) {
        return root + (inner || '') + JSON.stringify(query || {});
    }

    static getWithCache({ root, inner, query }, ttl, progressCB) {
        const tag = this.getTag({ root, inner, query });
        const bod = this.getCached(tag, ttl);

        if (bod) { // cache for 1 minute
            return Promise.resolve(bod);
        } else {
            return this.req.get(`${HOST}:${BACKEND_PORT}/${root}/${inner || ''}?${queryString(query)}`).withCredentials().on('progress', progressCB).then(res => {
                this.cacheRequest(tag, res);
                return Promise.resolve(res);
            }).catch(er => {
                this.invalidateRequest(tag);
                return Promise.reject(er);
            });
        }
    }

    static get({ root, inner, query, cache }, progress) {
        if (cache) {
            return this.getWithCache({ root, inner, query }, REQUEST_TTL, progress || EMPTY_CB);
        }

        return this.req.get(`${HOST}:${BACKEND_PORT}/${root}/${inner || ''}?${queryString(query)}`).withCredentials().on('progress', progress || EMPTY_CB);
    }

    static patch({ root, inner, body, query }) {
        this._invalidateModified(root);

        return this.req.patch(`${HOST}:${BACKEND_PORT}/${root}/${inner || ''}?${queryString(query)}`).send(body).withCredentials();
    }

    static put({ root, inner, body, query }) {
        this._invalidateModified(root);

        return this.req.put(`${HOST}:${BACKEND_PORT}/${root}/${inner || ''}?${queryString(query)}`).send(body).withCredentials();
    }

    static post({ root, inner, query, body }) {
        this._invalidateModified(root);

        return this.req.post(`${HOST}:${BACKEND_PORT}/${root}/${inner || ''}?${queryString(query)}`).send(body).withCredentials();
    }

    static delete({ root, inner, body, query }) {
        this._invalidateModified(root);

        return this.req.delete(`${HOST}:${BACKEND_PORT}/${root}/${inner || ''}?${queryString(query)}`).send(body).withCredentials();
    }

    static getUserSession() {
        return this.getWithCache({ root: 'user', inner: 'session' }, SESSION_TTL, EMPTY_CB);
    }

    static async sendError({ message, namespace, data }) {
        try {
            this.post({
                root: 'user',
                inner: `errorbeacon`,
                body: {
                    message,
                    namespace,
                    data: JSON.stringify(data)
                }
            });
        } catch (err) {
            console.error(err);
            return;
        }
    }

    static async interactionBeacon({ message, data }) {
        try {
            await this.post({
                root: 'user',
                inner: `interaction`,
                body: {
                    message,
                    data: data
                }
            });
        } catch (err) {
            console.error(err);
            return;
        }
    }

    static postMethod(endpoint, body) {
        return this.req.post(`${HOST}:${BACKEND_PORT}/${endpoint}`).send(body);
    }

    static getMethod(endpoint) {
        return this.req.get(`${HOST}:${BACKEND_PORT}/${endpoint}`);
    }
}

Networker.socket.on('reset', function () {
    console.warn('Backend updated refreshing');
    window.location.reload();
});
