import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ReactJson from 'react-json-view';
import Chip from '@material-ui/core/Chip';
import Networker from '../Utils/Networker';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextEditor from '../Components/TextEditor';
import {
  camelToRead
} from '../Utils/DataUtils';

import {
  getAllTags,
  getTriggerDescriptions
} from '../Utils/NotificationUtils';
import { Box, Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import Loader from './Loader';
import MobilePreview from './MobilePreview';

// TODO
// const validateRule = (rule) => {
//   if (rule.conditions && rule.event) {
//     if (rule.conditions.all || rule.conditions.any) {
//       if ((rule.conditions.any || rule.conditions.all).every(((condition) => {
//         const fact = condition.fact;
//         if (fact) {
//           return ['product', 'measurement', 'notification'].includes(fact) ||
//           fact.startsWith('user.') || fact.startsWith('product.');
//         } else {
//           console.error('Every condition must have a fact', condition);
//           return false;
//         }
//       }))) {
//         if (rule.event.type === 'send') {
//           return true;
//         }
//       }
//       console.error('Fact is undefined', (rule.conditions.any || rule.conditions.all));
//       return false;
//     }
//     console.error(
//       'Rule must have either any or all at top level condition', rule.conditions);
//     return false;
//   }
//   console.error(
//     'Rule needs conditions and event', rule);
//   return false;
// }


const getDefaultRule = () => {
  return [{
    conditions: {
      all: [{
        all: [{
          fact: 'user',
          operator: 'equal',
          value: '',
          path: '$'
        }, {
          fact: 'product',
          operator: 'equal',
          path: '$'
        }, {
          fact: 'measurement',
          operator: 'equal',
          path: '$'
        }]
      }]
    },
    event: {
      type: 'send'
    }
}];
}

function updateClipboard(newClip) {
  navigator.clipboard.writeText(newClip).then(() => {
    /* clipboard successfully set */
  }, function() {
    /* clipboard write failed */
  });
}
const clipEnable = (copy) => {
  try {
    navigator.permissions.query({ name: "clipboard-write" })
    .then(result => {
      if (result.state === "granted" || result.state === "prompt") {
        /* write to the clipboard now */
        updateClipboard(JSON.stringify(copy.src));
      }
    });
  } catch (e) {
    console.error('copy failed');
  }
};

const matchTags = (message, tags) => {
  const getTagRx = (tag) => {
    return new RegExp('[[](' + tag + ')[\\]]', 'gm');
  }

  const tagmatches = tags.map((tag, i) => {
    const regex = getTagRx(tag);
    return message.match(regex);
  });
  return tagmatches.map((matched, i) => {
    return [tags[i], !!matched];
  });
}

export default function MessageEditor({
  notification, handleSaveNotification, currentTab, productData
}) {

  const [tagMatches, setTagMatches] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [triggerDescriptions, setTriggerDescriptions] = useState({});
  const [newNotification, setNewNotification] = useState(notification);
  const [quillValue, setQuillValue] = useState({ editorHtml: notification?.message || '', title: notification?.subject || "", toggle: false, error: null })
  const [errorDialog, setErrorDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionButton, setActionButton] = useState(notification?.actionButton || []);
  const [actionValue, setActionValue] = useState("");
  const [openMobilePreview, setOpenMobilePreview] = useState(false);
  const [selectedIdentifier, setSelectedIdentifier] = useState("");

  useEffect(() => {
    document.querySelector(".MuiDialog-container").removeAttribute('tabindex');
  })

  useEffect(() => {
    getAllTags()
      .then(tags => {
        setAllTags(tags);
      })
      .catch((err) => {
        console.error(err);
        Networker.sendError({
          message: "Tag description fetch error",
          namespace: "dashboard.messageeditor",
          data: err.toString()
        });
      });
    getTriggerDescriptions()
      .then(desc => {
        setTriggerDescriptions(desc);
      })
      .catch((err) => {
        console.error(err);
        Networker.sendError({
          message: "Trigger description fetch error",
          namespace: "dashboard.messageeditor",
          data: err.toString()
        });
      });
  }, []);

  useEffect(() => {
    let messagePayload = newNotification.notificationType !== "text" ? quillValue.editorHtml : newNotification.message
    setTagMatches(matchTags(messagePayload, allTags));
  }, [allTags, newNotification.message, quillValue.editorHtml, newNotification.notificationType]);

  const handleChange = name => event => {
    const updatedValue = event.target.value; // Get the updated value directly from the event
    setSelectedIdentifier(updatedValue); // Update selectedIdentifier
  
    // Update newNotification using the updated selectedIdentifier value
    if (name.indexOf('.') > -1) {
      setNewNotification({
        ...newNotification,
        trigger: {
          ...newNotification.trigger,
          associatedLink: updatedValue, // Update associatedLink with updatedValue
          [name.split('.')[1]]: updatedValue
        }
      });
    } else if (event.target.hasOwnProperty('checked')) {
      setNewNotification({
        ...newNotification,
        associatedLink: updatedValue, // Update associatedLink with updatedValue
        [name]: event.target.checked
      });
    } else {
      setNewNotification({
        ...newNotification,
        associatedLink: updatedValue, // Update associatedLink with updatedValue
        [name]: updatedValue
      });
    }
  };
  
  const handleJSONChange = name => ({
    updated_src, name, namespace, new_value, existing_value
  }) => {
    setNewNotification({
      ...newNotification,
      trigger: {
        ...newNotification.trigger,
        value: updated_src,
        associatedLink: selectedIdentifier
      }
    });
    try {
      const str = JSON.stringify(updated_src);
      const obj = JSON.parse(str);
      if (!(obj instanceof Object)) {
        console.error('Rules must be object');
        return false;
      }
      if (!Array.isArray(obj)) {
        console.error('Rule must be array of rules');
        return false;
      }
      return obj.every((rule) => {
        return true;
        // return validateRule(rule);
      });
    } catch (e) {
      console.error(e);
      return false;
    }
  };
  const handleSave = () => {
    if (["push", "email"].includes(newNotification?.notificationType) && quillValue.error) return setErrorDialog(true)
    if (newNotification?.notificationType === "email" && quillValue?.title === "") {
      alert("Subject Required.")
    } else {
      newNotification?.notificationType !== "text" ? handleSaveNotification({
        ...newNotification,
        message: quillValue.editorHtml,
        subject: quillValue.title,
        imageUrl: newNotification?.notificationType === "push" ? quillValue.imageUrl : null,
        actionButton,
        associatedLink: selectedIdentifier // Use linkedSurveyValue here
      }) : handleSaveNotification(newNotification);
      
    }
  };


  // const handleSave = () => {
  //   if (["push", "email"].includes(newNotification?.notificationType) && quillValue.error) return setErrorDialog(true)
  //   if (newNotification?.notificationType === "email" && quillValue?.title === "") {
  //     alert("Subject Required.")
  //   } else {
  //     // Get the value of the TextField with the label "Linked Survey"
  //     const linkedSurvey = productData.externalLinks.map((link) => {
  //       console.log(link, '240')
  //       return document.getElementById(`name-${link.identifier}`).value;
  //     });
  //     console.log(linkedSurvey)
  //     newNotification?.notificationType !== "text" ? handleSaveNotification({
  //       ...newNotification,
  //       message: quillValue.editorHtml,
  //       subject: quillValue.title,
  //       imageUrl: newNotification?.notificationType === "push" ? quillValue.imageUrl : null,
  //       actionButton,
  //       linkedSurvey // Include the value of the Linked Survey TextField
  //     }) : handleSaveNotification(newNotification);
  //   }
  // };
  const handleCancel = () => {
    handleSaveNotification(null);
  };
  const descriptor = triggerDescriptions[newNotification.trigger.type];
  let jsonvalue = newNotification.trigger.value;
  if (descriptor) {
    if (descriptor.value === 'json' &&
      typeof jsonvalue === 'string') {
      try {
        jsonvalue = JSON.parse(newNotification.trigger.value) || getDefaultRule()
      } catch (e) {
        console.error('value is not valid json', e);
        jsonvalue = {};
      }
    }
  }

  const handleActionButton = () => {
    setActionButton(prev => ([...prev, actionValue]))
    setActionValue("")
  }

  const handleDeleteActionButton = (button) => {
    setActionButton(prev => prev.filter(actionButton => actionButton !== button))
  }

  if (!productData) {
    return <div>Loading...</div>; // Display loading indicator while data is being fetched
  }

  // Extracting unique identifiers from productData.externalLinks
  const identifiers = [...new Set(productData.externalLinks.map(link => link.identifier))];

  return descriptor && newNotification ? <>
    {openMobilePreview ? <MobilePreview actionButton={actionButton} quillValue={quillValue} setOpenMobilePreview={setOpenMobilePreview} openMobilePreview={openMobilePreview} /> : null}
    <Loader show={loading} />
    <Dialog
      open={errorDialog}
      onClose={() => setErrorDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ textAlign: "center", fontSize: "1.3rem", padding: "3rem" }}>
          {quillValue.error}
        </DialogContentText>
      </DialogContent>
    </Dialog>

    <Grid container spacing={3}
      alignItems="center" justifyContent="space-around">
      <Grid item xs={12}>
        <FormControlLabel
          control={<Switch
            checked={!!newNotification.active}
            onChange={handleChange('active')}
            value={newNotification.active}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />}
          label="Active"
        />
        {newNotification.notificationType === 'email' ?
          <>
            <TextField fullWidth label="Subject"
              value={quillValue.title}
              style={{ marginBottom: 15, marginTop: 15 }}
              variant="outlined"
              required
              onChange={(e) => {
                e.persist()
                setQuillValue(prev => ({ ...prev, title: e.target?.value }))
              }}
            ></TextField>
            <TextEditor setOpenMobilePreview={setOpenMobilePreview} setQuillValue={setQuillValue} quillValue={quillValue} setLoading={setLoading} /> </> : null}

        {/* <Grid item xs={12} style={{margin: 15}}>
          <Divider variant="middle">
          </Divider>
        </Grid> */}
        {newNotification.notificationType === "push" ? <>
          <TextField fullWidth label="Title"
            style={{ marginBottom: 15 }}
            value={quillValue.title}
            variant="outlined"
            required
            onChange={(e) => {
              e.persist()
              setQuillValue(prev => ({ ...prev, title: e.target?.value }))
            }} />
          <TextEditor setQuillValue={setQuillValue} quillValue={quillValue} setLoading={setLoading} setOpenMobilePreview={setOpenMobilePreview} /> </> : null}

        {newNotification.notificationType === "text" ? <TextField multiline rows="6"
          fullWidth rowsMax="32" label="Message"
          size="small"
          variant="outlined"
          value={newNotification.message}
          onChange={handleChange('message')}></TextField> : null}
        <Grid item xs={12}>
          {tagMatches.map(([key, match]) => {
            return <Tooltip title={`[${key}]`} key={key}>
              <Chip variant="outlined" key={key}
                color={match ? 'secondary' : 'primary'}
                size="small"
                onClick={() => {
                  setQuillValue(prev => ({ ...prev, toggle: !prev.toggle, editorHtml: prev.editorHtml, tag: `[${key}]` }))
                  setNewNotification({
                    ...newNotification,
                    message: newNotification.message + `[${key}]`,
                  });
                }}
                label={camelToRead(key)} />
            </Tooltip>
          })}
        </Grid>
        <Grid item xs={12}>
          {actionButton.map(button => {
            return <Tooltip title={button} key={button}>
              <Chip variant="outlined" style={{ marginTop: "0.5rem", marginRight: "0.5rem" }} key={button}
                color={'secondary'}
                size="medium"
                onDelete={() => handleDeleteActionButton(button)}
                label={button} />
            </Tooltip>
          })}
        </Grid>
        <Grid item xs={12} style={{ margin: 15 }}>
          <Divider variant="middle">
          </Divider>
        </Grid>
        <Grid item xs={12}>
          {descriptor.field ?
            <TextField select value={newNotification.trigger.field}
              label="N" onChange={handleChange('trigger.field')}>
              {descriptor.field.map((dataKey) => {
                return <MenuItem key={dataKey} value={dataKey}>
                  {camelToRead(dataKey)}
                </MenuItem>
              })}
              <MenuItem value={''}>?</MenuItem>
            </TextField>
            : null}
        </Grid>
        {newNotification.notificationType === "push" ? <Grid item xs={12}>
          <Box style={{ display: "flex", alignItems: "end", gap: "1rem", marginBottom: "0.5rem" }}>
            <TextField style={{ flexGrow: "1" }} id="standard-basic" label="Action" value={actionValue} onChange={(e) => setActionValue(e.target.value)} />
            <Button disabled={!actionValue} onClick={handleActionButton} style={{ fontSize: "12px" }} variant="contained">Add Action</Button>
          </Box>
        </Grid> : null}
        <Grid item xs={12}>
          <TextField fullWidth select label="trigger"
            value={newNotification.trigger.type}
            onChange={handleChange('trigger.type')}>
            {Object.keys(triggerDescriptions).sort().map((trig) => {
              return <MenuItem key={trig} value={trig}>{
                triggerDescriptions[trig].description
              }</MenuItem>
            })}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          {descriptor.value && descriptor.value !== 'json' ?
            <TextField type={descriptor.value} label="Value"
              value={newNotification.trigger.value}
              onChange={handleChange('trigger.value')}>
            </TextField> : null}
          {descriptor.value1 && descriptor.value1 !== 'json' ?
            <TextField style={{ marginLeft: "2em" }} type={descriptor.value1} label="Value1"
              value={newNotification.trigger.value1}
              onChange={handleChange('trigger.value1')}>
            </TextField> : null}
          {descriptor.value && descriptor.value === 'json' ?
            <ReactJson
              displayDataTypes={false}
              theme="solarized"
              indentWidth={2}
              sortKeys={true}
              enableClipboard={clipEnable}
              name="Rules (array)"
              onEdit={handleJSONChange('trigger.value')}
              onAdd={handleJSONChange('trigger.value')}
              onDelete={handleJSONChange('trigger.value')}
              defaultValue={{}}
              validationMessage="Check json-rules-engine npm package for more information"
              src={jsonvalue}>
            </ReactJson>
            : null}
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth select label="type"
            value={newNotification.notificationType}
            onChange={handleChange('notificationType')}>
            <MenuItem value={'email'}>Email</MenuItem>
            <MenuItem value={'text'}>Text</MenuItem>
            <MenuItem value='push'>Push Notifications</MenuItem>
          </TextField>
        </Grid>
        <FormControl fullWidth>
          <InputLabel id="linkedSurvey-label">Linked Survey</InputLabel>
          <Select
            labelId="linkedSurvey-label"
            id="linkedSurvey"
            value={selectedIdentifier} // Bind value to selectedIdentifier state
            onChange={handleChange('linkedSurvey')}
            label="Linked Survey"
          >
            {identifiers.map((identifier, index) => (
              <MenuItem key={index} value={identifier}>
                {identifier}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button fullWidth color="primary" onClick={handleCancel}>Cancel</Button>
        <Button fullWidth color="secondary" variant="contained" onClick={handleSave}>Save</Button>
      </Grid>
    </Grid></> : null;
}
