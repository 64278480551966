import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import CssBaseline from '@material-ui/core/CssBaseline';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Container, Grid, TextField, Avatar, Paper, List, ListItem, ListItemText, ListItemAvatar, Button, Typography } from '@material-ui/core';
import { Backdrop } from '@material-ui/core';

import AppContainer from '../Components/AppContainer';
import Loader from '../Components/Loader';
import MadeWithLove from '../Components/MadeWithLove';

import InternalApi from '../Utils/InternalApi';
import Networker from '../Utils/Networker';
import withStyle from '../style';

const DatasetActionButton = ({ buttonText, dialogTitle, dialogContent, onConfirm, buttonColor = "primary" }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Grid item>
      <Button variant="contained" color={buttonColor} onClick={() => setIsOpen(true)} style={{ margin: "0 8px" }}>
        {buttonText}
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => {
            setIsOpen(false);
            onConfirm();
          }} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default function DataCleaning({match}) {
  const [socket, setSocket] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [productNameFilter, setProductNameFilter] = useState('');
  const [datasetId, setDatasetId] = useState(null);
  const [pullDate, setPullDate] = useState(null);
  const [productToClean, setProductToClean] = useState({});
  const [showProductList, setShowProductList] = useState(true); // New state to toggle product list visibility
  const [datasetExists, setDatasetExists] = useState(null); // null indicates not checked, true/false will indicate the result
  // eslint-disable-next-line no-unused-vars
  const [includeRules, setIncludeRules] = useState({});
  const [filterRules, setFilterRules] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [orderRules, setOrderRules] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [outlierRules, setOutlierRules] = useState({});

  const history = useHistory();

  const updateProgress = _.throttle((imported, total) => {
    const progressPercent = (imported / total) * 100;
    setProgress(progressPercent);
    console.log(`Import progress: ${imported}/${total}`);
  }, 200); // Executes at most once every 200ms

  const onMessage = (data, socket) => {
    // console.log('Message received from server:', data);
    // Check for import progress updates
    if (data.imported !== undefined && data.total !== undefined) {
      updateProgress(data.imported, data.total);
    }

    // Handle final completion message
    if (data.done) {
      if (data.ok) {
        console.log('Operation completed successfully', data.message || '');
        // Perform any additional actions needed on completion
      } else {
        console.error('Operation failed', data.error || 'Unknown error');
        // Handle the error in UI
      }
      // Potentially deactivate loading state here if it was set for the operation
      setLoading(false);
    }
  };

  useEffect(() => {
    const connectSocket = async () => {
      try {
        const ws = await InternalApi.connect(onMessage);
        setSocket(ws);
        console.log('Connected to socket:', ws);
      } catch (error) {
        console.error('Failed to connect to socket:', error);
      }
    };
    connectSocket();

    return () => {
      if (socket) {
        InternalApi.disconnect(socket);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInitiateCleaning = (datasetId) => {
    if (datasetId) {
      // Navigate using the updated datasetId from the response
      history.push({
        pathname: `/datacleaning/${datasetId}`,
        state: { productToClean }
      });
    } else {
        // Handle the case where datasetId is not available
        console.error('Dataset ID is not available.');
    }
  };

  const refresh = useCallback(() => {
    Networker.get({
      root: 'products',
      inner: 'admin',
      cache: true
    }).then(({body}) => {
      setProducts(body.sort((a,b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 0 : -1;
      }));
      setLoading(false);
    }).catch((err) => {
      console.error(err);
    })
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFilterChange = useCallback(_.debounce((val) => {
    setProductNameFilter(val);
    setShowProductList(true); // Show the product list again when typing in the filter
  }, 150), []);  

  // Function run when a product is clicked, uses WebSocket to check if a dataset exists
  const handleProductClick = (product) => async (e) => {
    setProductToClean(product);
    setShowProductList(false); // Hide the product list

    if (!socket) {
        console.error("WebSocket is not connected.");
        return;
    }

    try {
        const msg = {
          productId: product._id, // Use the product ID to find the dataset
        };

        // Send the message through the WebSocket and wait for the response
        const response = await InternalApi.request(socket, 'dataset/find', msg);

        // Log the response from the server
        console.log('Response:', response);

        if (response && response.result && response.ok) {
            const dataset = response.result;
            console.log('Dataset exists:', dataset);
            setDatasetExists(true);
            setDatasetId(dataset._id); // Store datasetId when dataset exists
            setPullDate(dataset.pullDate); // Store pullDate
        } else {
            console.log('No dataset found');
            setDatasetExists(false);
            setDatasetId(null); // Reset datasetId when dataset doesn't exist
            setPullDate(null); // Reset pullDate
        }
    } catch (error) {
        console.error('Error fetching dataset via WebSocket:', error);
        setDatasetExists(false); // Assume no dataset if there's an error
        setDatasetId(null); // Reset datasetId
        setPullDate(null); // Reset pullDate
    }
  };

  // // Function run when a product is clicked, checks if a dataset exists using GET request
  // const handleProductClick = (product) => (e) => {
  //   setProductToClean(product);
  //   setShowProductList(false); // Hide the product list

  //   // Call the API to check for dataset existence
  //   Networker.get({
  //     root: `dataset/${product._id}`,
  //   }).then(response => {
  //     console.log('Response:', response);
  //     if (response && response.body && response.body._id) {
  //       console.log('Dataset exists:', response.body);
  //       setDatasetExists(true);
  //       setDatasetId(response.body._id); // Store datasetId when dataset exists
  //       setPullDate(response.body.pullDate); // Store pullDate
  //     } else {
  //       console.log('No dataset found');
  //       setDatasetExists(false);
  //       setDatasetId(null); // Reset datasetId when dataset doesn't exist
  //       setPullDate(null); // Reset pullDate
  //     }
  //   }).catch(error => {
  //     console.log(error);
  //     console.error('Error fetching dataset:', error);
  //     setDatasetExists(false); // Assume no dataset if there's an error
  //     setDatasetId(null); // Reset datasetId
  //     setPullDate(null); // Reset pullDate
  //   });
  // };

  // eslint-disable-next-line no-unused-vars
  const handleFilterRuleChange = (field, property, value) => {
    setFilterRules((prevRules) => ({
      ...prevRules,
      [field]: { ...prevRules[field], [property]: value },
    }));
  };  

  // Creating a dataset using WebSocket
  const createDataset = async () => {
    if (!socket) {
        console.error("WebSocket is not connected.");
        return;
    }
    setLoading(true);

    try {
        const msg = {
          productId: productToClean._id,
        };
        console.log('Creating dataset for product:', msg);

        const response = await InternalApi.request(socket, 'dataset/create', msg);
        if (response && response.dataset) {
            const dataset = response.dataset;
            console.log('Dataset created:', dataset);
            setLoading(false);
            history.push({
                pathname: `/datacleaning/${dataset._id}`,
                state: { productToClean }
            });
        } else {
            console.error('Failed to create dataset:', response.error);
            setLoading(false);
        }
    } catch (error) {
        console.error('WebSocket error during dataset creation:', error);
        setLoading(false);
    }
  };

  // // Creating a dataset using POST request (NO LONGER WORKING DUE TO SOCKET IMPLEMENTATION)
  // const createDataset = async () => {
  //   setLoading(true);

  //   try {
  //     const response = await Networker.post({
  //       root: `dataset/${productToClean._id}`,
  //       body: {}
  //     });

  //     if (response.body) {
  //       console.log('Dataset created:', response.body);
  //       const datasetId = response.body._id;
  //       setLoading(false); // Deactivate the backdrop before navigating away
  //       history.push({
  //         pathname: `/datacleaning/${datasetId}`,
  //         state: { productToClean }
  //       });
  //     } else {
  //       console.error('Failed to create dataset:', response.error);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.error('Failed to create dataset:', error);
  //     setLoading(false);
  //   }
  // };

  // Function to refresh the dataset entries using WebSocket
  const handleRefreshDataset = async () => {
    setLoading(true); // Activate loading state

    if (!socket) {
        console.error("WebSocket is not connected.");
        setLoading(false);
        return;
    }

    if (!productToClean || !productToClean._id) {
        console.error('Product ID is not available.');
        setLoading(false); // Deactivate loading state
        return; // Exit the function if no product ID is provided
    }

    try {
        // Constructing the message for dataset update
        const msg = {
          productId: productToClean._id,
          pullDate: new Date() // Sending the current date as the new pullDate
        };

        // Send the message through the WebSocket and wait for the response
        const response = await InternalApi.request(socket, 'dataset/patch', msg);

        console.log('Response:', response);
        if (response && response.dataset) {
          const dataset = response.dataset;
          console.log('Dataset pullDate updated:', dataset.pullDate);
          setLoading(false); // Deactivate the backdrop before navigating away
          history.push({
              pathname: `/datacleaning/${dataset._id}`,
              state: { productToClean }
          });
        } else {
          console.error('Failed to update dataset:', response.error);
          setLoading(false);
        }
    } catch (error) {
        console.error('Failed to update dataset via WebSocket:', error);
        setLoading(false); // Deactivate the backdrop before navigating away
    }
  };

  // // Function to refresh the dataset entries using PATCH request
  // const handleRefreshDataset = async () => {
  //   setLoading(true); // Activate loading state
  
  //   if (!productToClean || !productToClean._id) {
  //     console.error('Product ID is not available.');
  //     setLoading(false); // Deactivate loading state
  //     return; // Exit the function if no product ID is provided
  //   }
  
  //   try {
  //     const response = await Networker.patch({
  //       root: `dataset/${productToClean._id}`,
  //       body: { pullDate: new Date() } // Sending the current date as the new pullDate
  //     });
  
  //     if (response.body && response.body._id) {
  //       console.log('Dataset pullDate updated:', response.body);
  //       setLoading(false); // Deactivate the backdrop before navigating away
  //       history.push({
  //         pathname: `/datacleaning/${datasetId}`,
  //         state: { productToClean }
  //       });        
  //     } else {
  //       console.error('Failed to update dataset:', response.error);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.error('Failed to update dataset:', error);
  //     setLoading(false); // Deactivate the backdrop before navigating away
  //   }
  // };

  // eslint-disable-next-line no-unused-vars
  const assembleRulesJson = () => {
    return {
      include: includeRules,
      filter: filterRules,
      order: orderRules,
      outlier: outlierRules,
    };
  };  

  const classes = withStyle();

  return <div className={classes.root}>
    <CssBaseline />
    <AppContainer classes={classes} title="Data Cleaning" match={match}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid item xs={12} container spacing={1} justifyContent="flex-start" alignItems="center">
            <Grid item xs={11}>
              <TextField
                label="Filter"
                defaultValue=""
                onChange={(e) => handleFilterChange(e.target.value)}
                fullWidth
                autoFocus
              />
            </Grid>

            {/* Conditionally render the product list or selected product details */}
            {showProductList ? (
              <Grid item xs={12} md={6} lg={4}>
                <Paper>
                  <List>
                    {products.filter(({ name }) => name.toLowerCase().includes(productNameFilter.toLowerCase())).map((product) => (
                      <ListItem key={product._id} button divider dense onClick={handleProductClick(product)}>
                        <ListItemAvatar>
                          <Avatar alt={product.name.substr(0, 3)} src={product.iconURL} />
                        </ListItemAvatar>
                        <ListItemText primary={product.name} />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </Grid>
            ) : productToClean && (
              <Grid item xs={12} md={6} lg={4}>
                {/* Display selected product details with larger Avatar and name on the same row */}
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                  <Avatar
                    alt={productToClean.name.substr(0, 3)}
                    src={productToClean.iconURL}
                    style={{ width: 90, height: 90 }} // Adjust size as needed
                  />
                  <div>
                    <h3 style={{ margin: 0, fontSize: '1.5rem' }}>{productToClean.name}</h3>
                    <h3 style={{ margin: '5px 0 0 0', fontSize: '1.0rem' }}>{productToClean.productId}</h3>
                  </div>
                </div>

                {/* Configuration Area */}
                <div>
                  {/* Configuration area UI elements */}
                  <div>
                    {/* Include Rules */}
                  </div>
                </div>

                {/* Dataset Action Buttons */}
                <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                  {datasetExists === false ? (
                    <DatasetActionButton
                      buttonText="Create Dataset to Clean"
                      dialogTitle="Confirm Action"
                      dialogContent="Do you want to create a new dataset to clean for this product? This may take some time."
                      onConfirm={createDataset}
                      buttonColor="primary"
                    />
                  ) : datasetExists === true ? (
                    <>
                      <DatasetActionButton
                        buttonText="Start / Continue Cleaning Process"
                        dialogTitle="Confirm Action"
                        dialogContent="Do you want to start/continue the cleaning process for this product?"
                        onConfirm={() => handleInitiateCleaning(datasetId)}
                        buttonColor="secondary"
                      />
                      <DatasetActionButton
                        buttonText="Refresh Dataset Entries"
                        dialogTitle="Confirm Action"
                        dialogContent="Do you want to refresh the dataset entries for this product? This action will update the entries of the dataset. This may take some time."
                        onConfirm={handleRefreshDataset}
                        buttonColor="primary"
                      />
                      {pullDate && (
                        <Grid item xs={12} style={{ paddingLeft: '16px' }}> {/* Adjust the padding to align with the buttons */}
                          <Typography variant="body2" color="textSecondary">
                            Last Updated: {new Date(pullDate).toLocaleString()}
                          </Typography>
                        </Grid>
                      )}
                    </>
                  ) : null}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Container>
        {/* Backdrop */}
        <Backdrop open={loading} style={{ zIndex: theme => theme.zIndex.drawer + 1, color: '#fff' }}>
          <Loader show={loading} progress={progress} />
        </Backdrop>
        <MadeWithLove />
        </AppContainer>
  </div>;
}