import React, { useState, useEffect, useCallback, useRef } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Loader from '../Components/Loader';
import MadeWithLove from '../Components/MadeWithLove';

import AppContainer from '../Components/AppContainer';
import CSVUploader from '../Components/CSVUploader';
import Table from '../Components/Table';

import withStyle from '../style';
import Networker from '../Utils/Networker';
import {downloadObjectData} from '../Utils/DataUtils';
import Button from '@material-ui/core/Button';
import ConsumersTable from '../Components/EmailConsumersStatTable';
// ui for consumer stack table

const getAllAdminProducts = (org) => {
  return Networker.get({
    root: 'products',
    inner: 'admin',
    cache: true
  });
};

const getAllAdminOrganiztions = () => {
  return Networker.get({
    root: 'organizations',
    inner: 'admin',
    cache: true
  });
};

const getAllSUProducts = () => {
  return Networker.get({
    root: 'products',
    inner: 'all',
    cache: true
  });
};


const getUserSession = () => {
  return Networker.getUserSession();
};

const measurementToStringDisplay = (measurement) => {
  return measurement ? `${measurement.measurementTimeUTC}, ${measurement._id}, ${measurement.data?`Battery:${measurement.data.battery}, Temperature:${measurement.data.temperature}`:''}, ${measurement.meta?`OS:${measurement.meta.client}, Release:${measurement.meta.buildVersion}`:''}` : '';
}


const ProductIdSingletonFactory = (function(){
  function ProductIdClass() {
    this.value = undefined;
    this.getValue = () => {
      return this.value;
    }
    this.setValue = (newValue) => {
      this.value = newValue;
    }
  }
  let instance;

  return {
    getInstance: function(){
      if(!instance) {
        instance = new ProductIdClass();
        delete instance.constructor;
      }
      return instance;
    }
  }
})();


export default function ConnectionStatistics({match, history}){
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [openConsumersStack, setOpenConsumersStack] = useState(false);
  const [consumersStackData, setConsumersStackData] = useState([]);
  const [tableData, setTableData] = useState({});
  const [waitingForData, setWaitingForData] = useState(false);
  const [tableTotals, setTableTotals] = useState({})
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [sortObject, setSortObject] = useState({field:'email', order:1});
  const [hasRecruited, setHasRecruited] = useState(false);
  const [ShowMatchedEmails, setShowMatchedEmails] = React.useState(false);
  const [hasConnectionStatsAccess, setHasConnectionStatsAccess] = useState(false);

  const currentTab = match.params.productId;
  const ConsumersListDetailsFromApi = useRef([]);

  ProductIdSingletonFactory.getInstance().setValue(currentTab);

  const refreshProducts = useCallback(() => {
    setLoading(true);
    getUserSession()
      .then(async res => {
        setIsSuperUser(res.body.isSuperUser);
        const orgs = await getAllAdminOrganiztions();
       
        let connectionStatsAccess = false;
        for (const org of orgs.body){
          if(org.connectionStatsAccess){
            connectionStatsAccess = true;
            setHasConnectionStatsAccess(true);
            break;
          }
        }

        const getFunc = res.body.isSuperUser ? getAllSUProducts : getAllAdminProducts;
        try {
          const prods = await getFunc();
          const newProds = Array.from(new Set(prods.body));
          const filteredProds = newProds.filter(prod => {
            if (res.body.isSuperUser) {
              // setNotUpgraded(false);
              return true;
            }
            return connectionStatsAccess;
          }).sort((a, b) => {
            return a.name > b.name ? 0 : -1;
          });
          if (newProds.length > 0 && filteredProds.length === 0) {
            // setNotUpgraded(true);
            Networker.interactionBeacon({
              message: 'non-upgraded consumer connect view',
            });
          } else {
            setProducts(filteredProds);
          }
        } catch (e) {
          reportNetworkError(e, 'connection statistics products fetch error');
        }
      })
      .catch(e => {
        reportNetworkError(e, 'connection statistics products fetch error');
      });
  }, []);


  const reportNetworkError = (e, msg) => {
    console.error(e);
    window.alert('Network error: ' + msg);
    setLoading(false);
    Networker.sendError({
      message: msg,
      namespace: 'dashboard.connectionstats',
      data: e
    });
  }
  const convertToJSON = (data, headers) => {
    const json = [];
    data.forEach((row) => {
      if(row.errors.length>0)
        return
      const consumer = {optional:{}}
      for(let j=0; j<headers.length; j++){
        const currHeader = headers[j].trim().toLowerCase();
        consumer[currHeader] = row.data[j];
      }
      if(consumer.email && consumer.group)
        json.push(consumer);
    }); 
    return json;
  }

  const saveRecruitedConsumers = async (consumers, productId) => {
    try{
      await Networker.post({
        root: 'recruitedConsumers',
        inner: '',
        body: consumers,
        query: {productId: productId},
        cache: true
      });
    }catch(e){
      reportNetworkError(e, 'connection statistics data save error');
    }
  }

  const handleSaveComment = async (email, comments, productId) => {
    try{
      const {body:updated} = await Networker.put({
        root: 'recruitedConsumers',
        inner: 'comments',
        body: {email, comments},
        query: {productId: productId},
        cache: true
      });
      const tableDataUpdate = {...tableData};
      
      tableDataUpdate[email].comments = updated.comments;
      setTableData(tableDataUpdate);
    }catch(e){
      reportNetworkError(e, 'connection statistics comment save error');
    }
  }

  const handleFileLoad = (data, productId) => {
    setLoading(true);
    const headers = data[0].data.map(header => header.trim().toLowerCase());
    if(!headers.includes('email') || !headers.includes('group')){
      return window.alert('CSV file must contain the email column and the group column!');
    } 
    const jsonData = convertToJSON(data.slice(1), headers);

    saveRecruitedConsumers(jsonData, productId).then(()=>{
      getConsumers((newTableData, newTableTotals, newTableHeaders) => {
        setWaitingForData(false);
        setTableData(newTableData);
        setTableTotals(newTableTotals);
        setTableHeaders(newTableHeaders);
      });      
    });
  }

  const handleDeleteRecruitedConsumers = (productId) => {
    if(window.confirm('Are you sure you want to delete the existing recruited consumers?')) {
      setLoading(true);
      saveRecruitedConsumers([], productId).then(() => {
        getConsumers((newTableData, newTableTotals, newTableHeaders) => {
          setWaitingForData(false);
          setTableData(newTableData);
          setTableTotals(newTableTotals);
          setTableHeaders(newTableHeaders);    
        });      
      });
    }
  }
  const downloadEmailMismatchTable = (data, productId) => {
    // FIXME: because there is some misalignment in the EmailConsumersStatTable component, 
    // the headers have to be incorrectly mapped here for the headers to be correct 
    const headers = [
      { email: "Account Email" },
      { accountedEmail: "Recruited Email" },
      { phone: "Phone Number"},
      { macs: "Macs" },
      { userId: "User Id" }
    ];
    handleDownload(data, headers, productId, 2);
  }
  const handleDownload = (data, headers, productId,caseVal) => {
    if(caseVal === 1) headers.push({ email: "Email" }, { alert: "Alert" });
    const {field: sortField, order: sortOrder} = sortObject;
    const dataDownload = data
    .sort((a, b) => a[sortField] > b[sortField] ? sortOrder : -sortOrder)
    .map(row => {
      const updatedRow = {};
      headers.forEach(headerObj => {
        const [headerKey, headerValue] = Object.entries(headerObj)[0];

        if(row[headerKey] === undefined)
          updatedRow[headerValue] = "";
        else 
          updatedRow[headerValue] = row[headerKey];
      }); 
      return updatedRow;
    });

    if(dataDownload.length){
      const currentProd = products.filter(prod => prod._id === productId)[0];
      downloadObjectData(dataDownload, currentProd.name);
    }  
  }

  const handleSortByField = (field, order) => {
    setLoading(true);
    setSortObject({field, order});
  }
  
  const getconsumersList = async () => {
    setShowMatchedEmails(false);
    let filterRes;
    const filterResData = await Networker.get({
      root: 'recruitedConsumers/filter',
      inner: '',
      query: { productId: currentTab },
      cache: true
    });
    let updatedPotentialMatchedEmails = [];
    if (filterResData && filterResData.body) {
      filterRes = {};
      filterRes = filterResData;
      ConsumersListDetailsFromApi.current = filterResData.body;
    }
    if (filterRes && filterRes.body && filterRes.body.emailList.length > 0) {
      // setLoading(true);
      filterRes.body.emailList.map((item, index) => {
        // newEmailList.push({ recruiteEmail: item.email })
        filterRes.body.emailList[index]['phoneNumberChecked'] = false;
        filterRes.body.emailList[index]['recruitedEmailChecked'] = false;
        filterRes.body.emailList[index]['matchedEmailChecked'] = false;
        if(!filterRes.body.emailList[index].hasOwnProperty('extraAccountedEmails'))
          filterRes.body.emailList[index]['AllEmailDataAreThere'] = true;
        if (item.matchedEmail && item.matchedEmail.indexOf(',') > 0) {
          filterRes.body.emailList[index]['potentialEmails'] = item.matchedEmail.split(',');
          if (filterRes.body.emailList[index]['potentialEmails'] && filterRes.body.emailList[index]['potentialEmails'].length > 0) {
            filterRes.body.emailList[index]['potentialEmails'].map((matchedEmail) => {
              updatedPotentialMatchedEmails.push({
                potentialEmailChecked: false,
                potentialEmail: matchedEmail
              });
              return true;
            });
            filterRes.body.emailList[index]['potentialEmails'] = updatedPotentialMatchedEmails;
            updatedPotentialMatchedEmails = [];
          } else {
            filterRes.body.emailList[index]['potentialEmails'] = [];
          }
        } else {
          filterRes.body.emailList[index]['potentialEmails'] = []; 
        }
        return true
      });
      if (filterRes.body.emailList.length > 0 && filterRes.body.presentInvalidData.length === 0) {
          setLoading(false);
          setConsumersStackData(filterRes.body.emailList.length > 0 ? filterRes.body.emailList : []);
          setOpenConsumersStack(true);
      }
    } if (filterRes && filterRes.body && filterRes.body.presentInvalidData.length > 0) {
      let finalPresentInvalidData = [];
      // if (filterRes.body.presentInvalidData.length > 0 && filterRes.body.emailList.length === 0) finalPresentInvalidData = filterRes.body.presentInvalidData;
      filterRes.body.presentInvalidData.map((item, index) => {
        // newEmailList.push({ recruiteEmail: item.email });
        if (filterRes.body.emailList.length > 0) {
          let matchedIndex = filterRes.body.emailList.findIndex((emails) => emails.matchedEmail === item.email);
          const againMatchIndex = filterRes.body.emailList.findIndex((emails) => emails.accountedEmail === item.email);
          if (matchedIndex > -1) {
            filterRes.body.emailList[matchedIndex]['accountedEmail'] = item.email;
            filterRes.body.emailList[matchedIndex]['accountedEmailChecked'] = false;
          } else if (matchedIndex === -1) {
            let potentioalEmailIndex = -1;
            filterRes.body.emailList.map((emailItem, index) => {
              if (emailItem.potentialEmails && emailItem.potentialEmails.length > 0) {
                potentioalEmailIndex = emailItem.potentialEmails.findIndex((potential) => potential.potentialEmail === item.email);
                matchedIndex = index;
                const againMatchIndexToAdd = filterRes.body.emailList.findIndex((emails) => emails.accountedEmail === item.email);
                if (potentioalEmailIndex > -1 && againMatchIndexToAdd === -1 && 
                  !filterRes.body.emailList[index].accountedEmailMatchedEmail) {
                  filterRes.body.emailList[index]['accountedEmail'] = item.email;
                  filterRes.body.emailList[index]['accountedEmailChecked'] = false;
                  filterRes.body.emailList[index]['accountedEmailMatchedEmail'] = true;
                }
              }
              return true;
            });
            if (potentioalEmailIndex === -1 && matchedIndex === -1 && againMatchIndex === -1) {
              filterRes.body.emailList.push({
                'accountedEmail': item.email,
                'accountedEmailChecked': false,
                'extraAccountedEmails': true
              });
            }
            const againMatch = filterRes.body.emailList.findIndex((emails) => emails.accountedEmail === item.email);

            if (againMatch === -1 && againMatchIndex === -1) {
              filterRes.body.emailList.push({
                'accountedEmail': item.email,
                'accountedEmailChecked': false,
                'extraAccountedEmails': true
              });
            }
          }
        } else {
          finalPresentInvalidData.push({
            'accountedEmail': item.email,
            'accountedEmailChecked': false
          });
        }
        return true
      });
        setLoading(false);
        setConsumersStackData(filterRes.body.emailList.length > 0 ? filterRes.body.emailList : finalPresentInvalidData);
        setOpenConsumersStack(true);  
    }
    else {
      setOpenConsumersStack(true);
      setLoading(false);
      setConsumersStackData([]);
    }
  };

  const getConsumersStatsList = () => {
    getconsumersList();
    setConsumersStackData([]);
  }

  const getConsumers = useCallback(async function (callback){
    setWaitingForData(true);
    setLoading(true);
    
    if(!(currentTab && (isSuperUser || hasConnectionStatsAccess))){
      setWaitingForData(false);
      setLoading(false);
      return
    }
    try {  

      const {body:consumers} = await Networker.get({
        root: 'recruitedConsumers',
        inner: '',
        query: {productId: currentTab},
        cache: true
      });
      // if tab has changed (i.e., a different product is selected during the loading) ignore the results of the API request
      if(consumers.length > 0 && consumers[0].productId !== ProductIdSingletonFactory.getInstance().getValue()){
        return;
      }

      const {body} = await Networker.get({
        root: 'recruitedConsumers',
        inner: 'productInfo',
        query: {productId: currentTab},
        cache: true
      });

      const {notifications, surveys, hasRecruited:_hasRecruited} = body;

      setLoading(true);
      setHasRecruited(_hasRecruited);
      
      const newTableData = {}; 
      let newTableHeaders;
      let newTableTotals;
      
      if(_hasRecruited) {
        newTableTotals = {
          recruited: 0,
          registered: 0,
          connected: 0,
          firstUseDetected: 0,
          secondUseDetected: 0,
          thirdUseDetected: 0,
          fourthUseDetected: 0,
          lastUseDetected: 0,
          lastInteractionDetected: 0,
        };
        
        newTableHeaders = [
          {recruited: "Recruited"},
          {registered: "Registered"},
          {comments: "Comments"},
          {connected: "Connected"},
          {macs: "Macs"},
          {firstUseDetected: "First Use Detected"},
          {secondUseDetected: "Second Use Detected"},
          {thirdUseDetected: "Third Use Detected"},
          {fourthUseDetected: "Fourth Use Detected"},
          {lastUseDetected: "Last Use Detected"},
          {lastInteractionDetected: "Last Interaction Detected"},
          {numberOfInteractions: "Number of Interactions"},
          {numberOfUses: "Number of Uses"}
        ];
      } else {
        newTableTotals = {
          email: 0,
          registered: 0,
          connected: 0,
          firstUseDetected: 0,
          secondUseDetected: 0,
          thirdUseDetected: 0,
          fourthUseDetected: 0,
          lastUseDetected: 0,
          lastInteractionDetected: 0,
        };
        
        newTableHeaders = [
          {email: "Email"},
          {registered: "Registered"},
          {connected: "Connected"},
          {macs: "Macs"},
          {firstUseDetected: "First Use Detected"},
          {secondUseDetected: "Second Use Detected"},
          {thirdUseDetected: "Third Use Detected"},
          {fourthUseDetected: "Fourth Use Detected"},
          {lastUseDetected: "Last Use Detected"},
          {lastInteractionDetected: "Last Interaction Detected"},
          {numberOfUses: "Number of Uses"},
          {numberOfInteractions: "Number of Interactions"}
        ];
      }
    
      const addedNotificationHeaders = new Set();
      const addedSurveyHeaders = new Set();

      // add notifications set for product to headers 
      
      for(let notification of notifications){
        if (!addedNotificationHeaders.has(notification._id)){
          const newNotificationHeader = {[notification._id]:`Notification [${notification._id}${notification.notificationType ? ", "+notification.notificationType : ""}${notification.trigger && notification.trigger.type ? ", "+notification.trigger.type : ""}${notification.trigger && notification.trigger.field ? ", "+notification.trigger.field : ""}] Sent`};
          newTableHeaders.push(newNotificationHeader);
          addedNotificationHeaders.add(notification._id);
          newTableTotals[notification._id] = 0;
        }
      }
      
        
      // add surveys set for product to headers
      
      for(let survey of surveys){
        if (survey.meta){
          if (!addedSurveyHeaders.has(survey.meta.surveyId)){
            const newSurveyHeader = {[survey.meta.surveyId]:`Survey [${survey.meta.name ? survey.meta.name + ', ' : ''}${survey.meta.surveyId}] Responded`};
            newTableHeaders.push(newSurveyHeader);
            addedSurveyHeaders.add(survey.meta.surveyId);
            newTableTotals[survey.meta.surveyId] = 0;
          }
        }
      }
      
      
      for(let consumer of consumers){
        if(!newTableData[consumer.email]){
          newTableData[consumer.email] = {} 
        }
        if(_hasRecruited){
          newTableData[consumer.email]['recruited'] = `${consumer.group}, ${consumer.email}${consumer.customerId ? `, ${consumer.customerId}` : ""}${consumer.optional && consumer.optional.name ? `, ${consumer.optional.name}` : ""}${consumer.noUseThreshold ? `, ${consumer.noUseThreshold}` : ''}`;
        }
        
        newTableData[consumer.email]['registered'] = consumer.userId ? 
          `${consumer.createdAt ? consumer.createdAt + ', ' : ''}${consumer.userId}${consumer.phone?', '+consumer.phone:''}${consumer.meta ? `${consumer.meta.ethnicity?', '+consumer.meta.ethnicity:''}${consumer.meta.gender?', '+consumer.meta.gender:''}${consumer.meta.age?', '+consumer.meta.age:''}`: '' }` : '';
        newTableData[consumer.email]['connected'] = measurementToStringDisplay(consumer.connectionMeasurement);
        newTableData[consumer.email]['macs'] = consumer.macs ? `${consumer.macs.length}${consumer.macs.length > 0 ? ': '+consumer.macs.join(', '):''}` : '';
        newTableData[consumer.email]['firstUseDetected'] = measurementToStringDisplay(consumer.firstUseDetected);
        newTableData[consumer.email]['secondUseDetected'] = measurementToStringDisplay(consumer.secondUseDetected);
        newTableData[consumer.email]['thirdUseDetected'] = measurementToStringDisplay(consumer.thirdUseDetected);
        newTableData[consumer.email]['fourthUseDetected'] = measurementToStringDisplay(consumer.fourthUseDetected);
        newTableData[consumer.email]['lastUseDetected'] = measurementToStringDisplay(consumer.lastUseDetected);
        newTableData[consumer.email]['lastInteractionDetected'] = measurementToStringDisplay(consumer.lastInteractionDetected);

        
        newTableData[consumer.email]['numberOfUses'] = consumer.numberOfUses;
        newTableData[consumer.email]['numberOfInteractions'] = consumer.numberOfInteractions; 
        
        newTableData[consumer.email]['comments'] = consumer.comments;
        newTableData[consumer.email]['email'] = consumer.email;


        // check if user should trigger no use alert
        const referenceDate = consumer.lastUseDetected ? consumer.lastUseDetected.measurementTimeUTC 
          : consumer.connectionMeasurement ? consumer.connectionMeasurement.measurementTimeUTC 
            : consumer.createdAt ? consumer.createdAt 
              : Date.now();
        const daysSince = parseInt( ( Date.now() - new Date(referenceDate) ) / (1000 * 60 * 60 * 24) );
        newTableData[consumer.email]['alert'] = consumer.noUseThreshold > 0 ? daysSince > consumer.noUseThreshold : false;


        // prepare notification stats
        const consumerNotificationsCounted = new Set();
        const consumerSurveysCounted = new Set();

        
        for(let notification of consumer.notifications){
          newTableData[consumer.email][notification.notification] = `${notification.sentAt}, ${notification._id}, ${notification.measurement}`;
          if (!addedNotificationHeaders.has(notification.notification)){
            const newNotificationHeader = {[notification.notification]:`Notification [${notification.notification}${notification.notificationType ? ", "+notification.notificationType : ""}${notification.trigger && notification.trigger.type ? ", "+notification.trigger.type : ""}${notification.trigger && notification.trigger.field ? ", "+notification.trigger.field : ""}] Sent`};
            newTableHeaders.push(newNotificationHeader);
            addedNotificationHeaders.add(notification.notification);
          }
          if (!Object.keys(newTableTotals).includes(notification.notification)){
            newTableTotals[notification.notification] = 1;
            consumerNotificationsCounted.add(notification.notification);
          } else if (!consumerNotificationsCounted.has(notification.notification)){
            newTableTotals[notification.notification] += 1;
            consumerNotificationsCounted.add(notification.notification);
          }
        }
        
            
        // prepare survey stats
        
        for(let key of Object.keys(consumer.allSurveyResponses)){
          const surveyResponse = consumer.allSurveyResponses[key];

          if(surveyResponse && surveyResponse.response && surveyResponse.response.surveyId){ 
            newTableData[consumer.email][surveyResponse.response.surveyId] = `${surveyResponse.response.meta && surveyResponse.response.meta.surveyDateModified ? surveyResponse.response.meta.surveyDateModified+", " : ""}${surveyResponse.response.meta && surveyResponse.response.meta.responseId ? surveyResponse.response.meta.responseId+", " : ""}${surveyResponse._id}, ${surveyResponse.measurementId}`;

            if (!addedSurveyHeaders.has(surveyResponse.response.surveyId)){
              const newSurveyHeader = {[surveyResponse.response.surveyId]:`Survey [${surveyResponse.response.surveyId}] Responded`};
              newTableHeaders.push(newSurveyHeader);
              addedSurveyHeaders.add(surveyResponse.response.surveyId);
            }
            
            if (!Object.keys(newTableTotals).includes(surveyResponse.response.surveyId)){
              newTableTotals[surveyResponse.response.surveyId] = 1;
              consumerSurveysCounted.add(surveyResponse.response.surveyId);
            } else if (!consumerSurveysCounted.has(surveyResponse.response.surveyId)){
              newTableTotals[surveyResponse.response.surveyId] += 1;
              consumerSurveysCounted.add(surveyResponse.response.surveyId);
            }
          }
        }
        

        // update totals
        if(_hasRecruited){
          newTableTotals.recruited += consumer.email ? 1 : 0;
        }else{
          newTableTotals.email += consumer.email ? 1 : 0;
        }
        newTableTotals.registered += consumer.userId ? 1 : 0;
        newTableTotals.connected += consumer.connectionMeasurement ? 1 : 0;
        newTableTotals.firstUseDetected += consumer.firstUseDetected ? 1 : 0;
        newTableTotals.secondUseDetected += consumer.secondUseDetected ? 1 : 0;
        newTableTotals.thirdUseDetected += consumer.thirdUseDetected ? 1 : 0;
        newTableTotals.fourthUseDetected += consumer.fourthUseDetected ? 1: 0;
        newTableTotals.lastUseDetected += consumer.lastUseDetected ? 1: 0;
        newTableTotals.lastInteractionDetected += consumer.lastInteractionDetected ? 1 : 0;
      }
      
      // if tab has changed (i.e., a different product is selected during the loading) ignore the results of the API request
      if(consumers.length > 0 && consumers[0].productId !== ProductIdSingletonFactory.getInstance().getValue()){
        return;
      }

      callback(newTableData, newTableTotals, newTableHeaders);
      
    } catch(e){
      reportNetworkError(e, 'connection statistics data fetch error');
    }
  
  }, [currentTab, isSuperUser, hasConnectionStatsAccess]);

  

  useEffect(() => {
    if (!currentTab && products.length > 0) {
      history.push('/connectionstatistics/' + products[0]._id)
    } else if (products.length === 0){
      refreshProducts();
    }
  }, [currentTab, history, products, refreshProducts]);
    
  useEffect(() => {  
    getConsumers((newTableData, newTableTotals, newTableHeaders) => {
      setWaitingForData(false);
      setTableData(newTableData);
      setTableTotals(newTableTotals);
      setTableHeaders(newTableHeaders);
      // setLoading(false);
    });
      
    return () => {
      setLoading(false);
      setWaitingForData(false);
      setTableData({});
      setTableTotals({});
    }

  }, [
    currentTab,
    getConsumers
  ]);


  const handleTabChange = (val) => (e) => {
    
    if(val !== currentTab) {
      history.push('/connectionstatistics/' + val);
      setLoading(true);
      setSortObject({field:'email', order:1});
    }
  };

  const classes = withStyle();
  const buttonclosePopup = () => {
    setOpenConsumersStack(false);
    setConsumersStackData([]);
  }

  return (<div className={classes.root}>
    <CssBaseline />
    <Loader show={loading} />
    <AppContainer classes={classes} title="Connection Statistics" match={match}>
      <div className={classes.appBarSpacer} />
      <Grid container alignItems="flex-start" justifyContent="space-between" spacing={2}>
        {!isSuperUser && !hasConnectionStatsAccess? <Grid item xs={6}>
          <Typography variant="h4">
          </Typography>
        </Grid> : 
        <>
          <Grid item xs={12} md={3}>
            <Paper>
              <List className={classes.connectList} dense>
                {products.map(prod => {
                  return <ListItem key={prod._id} button divider
                    dense
                    selected={currentTab === prod._id}
                    onClick={handleTabChange(prod._id)}>
                    <ListItemAvatar>
                      <Avatar alt={prod.name.substr(0, 3)} src={prod.iconURL} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={prod.name}>
                    </ListItemText>
                  </ListItem>
                })}
              </List>
            </Paper>

            <>
              <CSVUploader OnFileLoad={(data) => {handleFileLoad(data, currentTab)}}/>
              
              <aside
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 28
                }}
              >
                <Button
                  type="submit"
                  onClick={()=>handleDownload( Object.values(tableData), tableHeaders, currentTab )}
                  variant="contained"
                  color="secondary"
                >
                  Download CSV File
                </Button>
              </aside>
              <aside
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 28
                }}
              >
                <Button
                  type="submit"
                  onClick={() => { setLoading(true);getConsumersStatsList()}}
                  variant="contained"
                  color="primary"
                >
                  Consumers Stats
                </Button>
                </aside>
                
              {hasRecruited && <aside
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
                >
                {/* <Button
                  type="submit"
                  onClick={() => setOpenConsumersStack(true)}
                  variant="contained"
                  color="primary"
                >
                  Consumers Stack
                </Button> */}
                <Button
                  type="submit"
                  onClick={()=>handleDeleteRecruitedConsumers(currentTab)}
                  variant="contained"
                  color="primary"
                >
                  Delete Recruited Consumers
                </Button>
              </aside>}
            </>
            
          </Grid>
          
          <Grid item xs={12} md={9}>
            <List className={classes.connectList} dense>
              <Table data = {tableData} 
                fields = {tableHeaders}
                totals = {tableTotals}
                waitingForData = {waitingForData}
                onSortByField = {handleSortByField}
                sortObject = {sortObject}
                onSaveComment = {(email, comment) => handleSaveComment(email, comment, currentTab)}
                onTableLoaded = {()=>setLoading(false)}
              />
            </List>
          </Grid>
        </>}          
      </Grid>
      <MadeWithLove />
      
      {/* consumers stack modal along with table */}
      {openConsumersStack && <ConsumersTable
        show={openConsumersStack}
        consumersStackList={consumersStackData.length > 0 ? consumersStackData : []}
        currentTabId={currentTab}
        downloadEmailMismatchTableApi={downloadEmailMismatchTable}
        ShowMatchedEmailsList={ShowMatchedEmails}
        closePopup={buttonclosePopup}
        ConsumersListDetailsDataFromApi={ConsumersListDetailsFromApi.current}
        exportCosntStatsToCSVApi={downloadEmailMismatchTable}
      />
      }
      {/*end of consumers table  */}
      {/* match filter */}
    </AppContainer>
    <style>
      {
        ` .consumnerStackWrapper{
          border: 1px solid #8080805e;
          }
          .notfilterWrapper, .donotShow{
            display:none;
          }
          .userConsumersList{
            width: auto !important;
            height: auto !important;
            overflow: auto !important;
          }
          .userConsumersList::-webkit-scrollbar-track {
            background: #f1f1f1 !important;
          }

          .userConsumersList::-webkit-scrollbar-thumb {
            background: gray !important;
          }

          .userConsumersList::-webkit-scrollbar-thumb:hover {
            background: #555 !important;
          } 
          .userConsumersList::-webkit-scrollbar {
            width: 10px !important;
            height: 10px !important;
          }  
          #alert-dialog-title{
            color: #ff7a59;
            text-align: center;
            // text-decoration-line: underline;
          }
          .consumerTableCellWidth{
            width:auto;
            min-width: 300px !important;
            word-break: 'break-all';
          }
          .consumerEmailTableCellWidth{
            width:auto !important;
            min-width: 300px !important;
            word-break: 'break-all' !important;
          }
          .CloseButton, .CloseButton:hover, .CloseButton:focus{
              background: #ff7a59;
              color: white;
              font-weight:700;
          }
          .sendBtn, .sendBtn:hover, .sendBtn:focus{
              background: #18b718e8;
              color: white;
              font-weight:700;
              margin-top: 12px;
          }
          .subjectWrapper{
            margin-bottom:10px !important;
          }
          .subjectPlace{
            margin-right:10px;
          }
          .closeBtn{
            text-align: end;
            float: right;
            color: gray;
            font-size: 15px;
            cursor:pointer;
          }   
        .switchButtonText{
          padding: 5px;
          /* background: #ff7a59; */
          color: #ff7a59;
          font-weight: 700;
          /* border: 1px solid #ff7a59; */
          margin: 0px 10px;
          text-decoration: underline;
        }
      `
      }
      </style>
  </div>)
}