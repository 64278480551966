import React from 'react'

import { CSVReader } from 'react-papaparse'
import Button from '@material-ui/core/Button';

import withStyle from '../style';

const buttonRef = React.createRef()


const handleOpenDialog = (e) => {
  // Note that the ref is set async, so it might be null at some point 
  if (buttonRef.current) {
    buttonRef.current.open(e)
  }
}

const handleOnError = (err, file, inputElem, reason) => {
  console.log(err)
}

export default function CSVUploader({OnFileLoad}) {
  
  const classes = withStyle();
  return (
    <CSVReader
      ref={buttonRef}
      header={true}
      onFileLoad={(data) => {
        OnFileLoad(data);
        buttonRef.current.removeFile();
      }}
      // onFileLoad={OnFileLoad}
      onError={handleOnError}
      noClick
      noDrag
      
    >
      {({ file }) => (
        <aside
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 10
          }}
        >
          <Button
            type="submit"
            // fullWidth
            onClick={handleOpenDialog}
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            Upload CSV File
          </Button>
          
        </aside>
      )}
    </CSVReader>
  )
}