import { HOST, BACKEND_PORT } from './Networker';

// Path for internal api socket
const SOCKET_PATH = '/socket/';

export default class InternalApi
{
    // Return a Promise resolving to a WebSocket object to be used with further calls.
    static connect(onMessage)
    {
        // Whoever designed this doesn't know what a host is....
        const hostUrl = new URL(HOST);
        const host = hostUrl.host;

        const protocol = (host === 'localhost' ? 'ws' : 'wss');
        const url = new URL(SOCKET_PATH, `${protocol}://${host}:${BACKEND_PORT}`);

        return new Promise((res, rej) => {
            const socket = new WebSocket(url);

            socket.addEventListener('error', (event) => {
                console.error(event);
                rej(event);
            }, { once: true });

            socket.addEventListener('open', (_) => res(socket), { once: true });

            if (onMessage) {
                socket.addEventListener('message', (event) => onMessage(JSON.parse(event.data), socket));
            }

            if (socket.readyState === 1) {
                res(socket);
            }
        });
    }

    static request(socket, endpoint, args, clientCallback)
    {
        if (socket.readyState > 1){
            return Promise.reject('Already closing!');
        }

        return new Promise((res, rej) => {
            const onMessage = (event) => {
                const msg = JSON.parse(event.data);
                if (clientCallback) { clientCallback(msg, socket); }

                if (msg.done)
                {
                    socket.removeEventListener('message', onMessage);
                    res(msg);
                }
            };

            socket.addEventListener('message', onMessage);

            socket.addEventListener('error', (event) => {
                socket.removeEventListener('message', onMessage);
                console.error(event);

                rej(event);
            }, { once: true });

            socket.send(JSON.stringify({
                function: endpoint,
                args: args
            }));
        });
    }

    static disconnect(socket)
    {
        if (socket.readyState === 3) {
            return Promise.resolve();
        }

        return new Promise((res, rej) => {
            socket.addEventListener('error', (event) => {
                console.error(event);
                rej(event);
            }, { once: true });

            socket.addEventListener('close', (_) => res(), { once: true });
            socket.close();
        });
    }
}
