import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone';
import ExpandLessTwoToneIcon from '@material-ui/icons/ExpandLessTwoTone';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function BasicTable({fields, data, waitingForData, totals, onSaveComment, onSortByField, sortObject, onTableLoaded}) {

  const classes = useStyles();

  const [currentCommentEmail, setCurrentCommentEmail] = useState('');
  const [currentComment, setCurrentComment] = useState(''); 
  const [dataArray, setDataArray] = useState([])

  const handleUpdate = (userEmail) => {
    setCurrentCommentEmail(userEmail);
    setCurrentComment(data[userEmail]['comments']);
  }

  useEffect(()=>{
    const newDataArray = Object.values(data);
    const {field, order} = sortObject;

    // if field does not exist for row, use empty string instead
    newDataArray.sort((a, b) => (a[field] ? a[field] : '') > (b[field] ? b[field] : '') ? order : -order);
    
    setDataArray(newDataArray); 
    
    if(!waitingForData)
      onTableLoaded();
  }, [data, sortObject, onTableLoaded, waitingForData]);
  
  const handleSaveComment = (userEmail) => {
    onSaveComment(userEmail, currentComment).then(()=>{

    }).catch((err)=>{
      console.log(err);
    }).finally(()=>{
      setCurrentCommentEmail('');
      setCurrentComment('');
    });
  }

  const handleCommentChange = (event) => {
    setCurrentComment(event.target.value);
  }

  return (
    // <TableContainer component={Paper}>
      <Table className = {classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {fields.map(field => {
              const key = Object.keys(field)[0];
              const value = Object.values(field)[0];
              return <TableCell key={key} align={"left"}>
                <b>{`${value} ${totals[key] !== undefined? "("+totals[key]+")": ""}`}</b>
                {!['comments'].includes(key) && 
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ExpandMoreTwoToneIcon
                      style={{cursor:"pointer", fontSize:sortObject.field === key && sortObject.order === 1 ? 45 : 25}}
                      color={sortObject.field === key && sortObject.order === 1 ? "secondary" : "primary"}
                      onClick={()=>onSortByField(key, 1)}
                    />

                    <ExpandLessTwoToneIcon
                      style={{cursor:"pointer", fontSize:sortObject.field === key && sortObject.order === -1 ? 45 : 25}}
                      color={sortObject.field === key && sortObject.order === -1 ? "secondary" : "primary"}
                      onClick={() => onSortByField(key, -1)}
                    />
                </Grid>}
                
              </TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataArray.map((row) => 
            <TableRow key={row.email} style={{background: row.alert && "yellow"}}>
              {fields.map(field => {
                  const key = Object.keys(field)[0];

                  if(fields.indexOf(field) === 0)
                    return <TableCell key={key} component="th" scope="row"> {row[key] && `${row[key]}`} </TableCell>
                  
                  else if(key === "comments")
                    return row.recruited && <TableCell key={key}> 
                      <textarea 
                        disabled={row.email !== currentCommentEmail} 
                        rows={3} 
                        value={row.email === currentCommentEmail ? currentComment : row["comments"]}
                        onChange={handleCommentChange}
                      /> 
                      <Button disabled={row.email === currentCommentEmail} onClick={() => handleUpdate(row.email)} color="secondary">
                        Edit
                      </Button> 
                      <Button disabled={row.email !== currentCommentEmail} onClick={() => handleSaveComment(row.email)} color="secondary">
                        Save
                      </Button> 
                    </TableCell>
                  else
                    return <TableCell key={key}>{row[key] && `${row[key]}`}</TableCell>
              })}
            </TableRow>
          )}
        </TableBody>
      </Table>
    // </TableContainer>
  );
}